<template>
  <v-form v-model="objective_is_valid" ref="form">
    <!-- notification area -->
    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-alert
      v-model="submitted"
      transition="fade-transition"
      type="success"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">Successfully Submitted</div>
    </v-alert>

    <!-- loading screen -->
    <v-row v-if="loading" justify="center">
      <v-col xs="12" xl="8">
        <v-col>
          <h3 class="text-center">
            <v-progress-circular indeterminate class="ml-3" color="primary" />
            Please be patient, this may take a while.
          </h3>
        </v-col>
      </v-col>
    </v-row>

    <v-row v-if="!loading">
      <v-col>
        <!-- header text -->
        <v-row>
          <v-col>
            <h1>
              {{
                view_type === "edit"
                  ? `Update ${objective.subject}`
                  : "Create Objective"
              }}
            </h1>

            <p v-if="view_type !== 'edit'" class="grey--text">
              When creating an objective template, it's crucial to begin by
              clearly defining the objective itself. This should be a concise
              statement that encapsulates the desired outcome or result.
              Consider incorporating measurable criteria that will indicate
              progress and success.
            </p>
          </v-col>
        </v-row>

        <!-- select type -->
        <v-row>
          <v-col>
            <v-select
              v-model="objective.type"
              :items="types"
              label="Select Type"
              :rules="rule.required"
            />
          </v-col>
        </v-row>

        <!-- service ticket type -->
        <v-row v-if="objective.type === 'Service Ticket'">
          <v-col>
            <v-select
              v-model="objective.service_ticket_type"
              :items="service_ticket_types"
              label="Select Service Ticket Type"
              :rules="rule.required"
            />
          </v-col>
        </v-row>

        <!-- specified service ticket type -->
        <v-row v-if="objective.service_ticket_type === 'Other'">
          <v-col>
            <v-text-field
              v-model="objective.specified_service_ticket_type"
              label="Please Specify"
              :rules="rule.required"
              outlined
            />
          </v-col>
        </v-row>

        <!-- subject -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="objective.subject"
              label="Subject"
              :rules="rule.required"
              outlined
            />
          </v-col>
        </v-row>

        <!-- description -->
        <v-row>
          <v-col>
            <v-textarea
              v-model="objective.description"
              label="Description"
              auto-grow
              outlined
            />
          </v-col>
        </v-row>

        <!-- order and day_limit -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="objective.day_limit"
              hide-details="auto"
              label="Day Limit"
              type="number"
              outlined
            />
          </v-col>
          
          <v-col> </v-col>
        </v-row>

        <!-- obj_tsk_templs -->
        <v-row v-if="objective.obj_tsk_templs.length > 0">
          <v-col>
            <p class="text-subtitle-1 grey--text">Added Tasks:</p>
            <v-data-table
              :headers="task_header"
              :items="objective.obj_tsk_templs"
              :items-per-page="-1"
            >
              <template v-slot:[`item.description`]="{ item }">
                <div class="text-wrap">
                  {{ item.description }}
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <div
                  v-if="!adding_task && !updating_task"
                  class="d-flex align-center justify-end"
                >
                  <v-btn
                    class="mr-5"
                    icon
                    small
                    @click="
                      updating_task = true;
                      task_to_update = item;
                    "
                    title="Edit"
                  >
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    small
                    color="red"
                    title="Delete"
                    @click="handle_delete_task(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- add task button -->
        <v-row>
          <v-col>
            <v-btn
              v-if="!adding_task && !updating_task"
              @click="adding_task = true"
            >
              Add Task
            </v-btn>
          </v-col>
        </v-row>

        <!-- add task component -->
        <v-row>
          <v-col>
            <v-card v-if="adding_task || updating_task" class="rounded-lg">
              <v-card-title>
                <div class="text-h6 d-flex align-center">
                  {{ adding_task ? "Add" : "Update" }} Task
                </div>

                <v-spacer></v-spacer>

                <v-btn
                  icon
                  class="ml-2"
                  color="red"
                  title="Close"
                  @click="
                    adding_task = false;
                    updating_task = false;
                  "
                >
                  <v-icon> mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <AddTask
                  :adding_task="adding_task"
                  :updating_task="updating_task"
                  :task_to_update="task_to_update"
                  @submit="handle_add_task($event)"
                  @close="
                    adding_task = false;
                    updating_task = false;
                  "
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- actions -->
        <v-row>
          <v-col>
            <v-btn color="primary" @click="handle_submit"> Submit </v-btn>

            <v-btn class="ml-4" @click="$router.go(-1)"> Cancel </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import API from "@/plugins/API";
import AddTask from "@/components/task_manager/AddTask.vue";

export default {
  props: ["view_type"],

  components: {
    AddTask,
  },

  data: () => ({
    loading: false,
    error: false,
    errorMessage: "",
    submitted: false,
    objective_is_valid: false,
    task_is_valid: false,

    adding_task: false,
    updating_task: false,
    task_to_update: null,

    task_header: [
      { text: "Subject", value: "subject" },
      { text: "Description", value: "description" },
      { text: "", value: "actions", sortable: false },
    ],

    types: ["Service Ticket", "Internal Step"],

    service_ticket_types: [
      "Payout Request",
      "Balance Request",
      "Payment Amount Change",
      "Payment Date Change",
      "Payment Collection",
      "Property Taxes",
      "Insurance",
      "Demand Letter",
      "Foreclosure",
      "Other",
    ],

    objective: {
      subject: null,
      description: null,
      type: null,
      day_limit: null,
      service_ticket_type: null,
      specified_service_ticket_type: "",
      obj_tsk_templs: [],
    },

    rule: {
      required: [(v) => !!v || "This is required"],
    },
  }),

  methods: {
    handle_add_task(task) {
      if (this.adding_task) {
        let new_order = null;

        if (this.objective.obj_tsk_templs.length === 0) {
          new_order = 1;
        } else {
          // get highest order
          let highest_order = Math.max.apply(
            Math,
            this.objective.obj_tsk_templs.map(function (task) {
              return task.order;
            })
          );

          new_order = highest_order + 1;
        }

        this.objective.obj_tsk_templs.push({
          order: new_order,
          subject: task.subject,
          description: task.description,
          task_order: task.task_order,
          day_limit: task.day_limit,
        });
      }

      if (this.updating_task) {
        let index = this.objective.obj_tsk_templs.indexOf(this.task_to_update);

        this.objective.obj_tsk_templs[index] = task;
      }

      this.adding_task = false;
      this.updating_task = false;
    },

    handle_delete_task(task) {
      if (window.confirm("Are you sure you want to delete this task?")) {
        let index = this.objective.obj_tsk_templs.indexOf(task);

        this.objective.obj_tsk_templs.splice(index, 1);
      } else {
        console.log("cancelled");
        return;
      }
    },

    async handle_submit() {
      try {
        this.loading = true;

        // validate form
        const valid = this.$refs.form.validate();
        if (!valid) {
          alert("Please fill the required field.");
          this.loading = false;
          return;
        }

        let response = null;

        console.log("view_type: ", this.view_type);

        if (this.view_type === "add") {
          response = await API().post(
            "api/internal-admin/objective-template/add",
            {
              objective: this.objective,
            }
          );
        }

        if (this.view_type === "edit") {
          response = await API().patch(
            "api/internal-admin/objective-template/edit",
            {
              objective: this.objective,
            }
          );
        }

        console.log(response);

        if (response.status === 201) {
          this.submitted = true;

          setTimeout(() => {
            this.$router.go(-1);
            this.loading = false;
          }, 2000);
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong. Please try again.";
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
        this.loading = false;
      }
    },

    async get_objective_data() {
      try {
        this.loading = true;

        let response = await API().get(
          `api/internal-admin/objective-template/read?id=${this.$route.query.id}`
        );

        // console.log(response);

        if (response.status === 200) {
          this.objective = response.data;
          this.loading = false;
        } else {
          this.error = true;
          this.errorMessage = "Unable to read objective data.";

          setTimeout(() => {
            this.$router.go(-1);
            this.loading = false;
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
        this.loading = false;
      }
    },
  },

  mounted() {
    if (this.view_type === "edit") {
      this.get_objective_data();
    }
  },
};
</script>
