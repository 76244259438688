<template>
  <v-form v-model="task_is_valid" ref="form">
    <v-row>
      <v-col>
        <v-text-field
          v-model="task.subject"
          label="Subject"
          hide-details="auto"
          :rules="[rule.required]"
          dense
          outlined
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-textarea
          v-model="task.description"
          label="Description"
          hide-details="auto"
          auto-grow
          dense
          outlined
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="task.task_order"
          hide-details="auto"
          label="Order"
          type="number"
          dense
          outlined
        />
      </v-col>

      <v-col>
        <v-text-field
          v-model="task.day_limit"
          hide-details="auto"
          label="Day Limit"
          type="number"
          dense
          outlined
        />
      </v-col>

      <v-col>
        <v-select :items="[]" label="Select Helpers" hide-details="auto" dense />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1">
        <v-btn color="primary" @click="handle_submit"> submit </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rule from "@/plugins/rule";

export default {
  props: ["adding_task", "updating_task", "task_to_update"],

  data: () => ({
    task_is_valid: false,

    task: {
      subject: null,
      description: null,
      task_order: null,
      day_limit: null,
    },

    rule: rule,
  }),

  methods: {
    handle_submit() {
      // validate form
      this.task_is_valid = this.$refs.form.validate();

      // if form is not valid, return
      if (!this.task_is_valid) {
        alert("Please fill the required field.");
        return;
      }

      // emit event
      this.$emit("submit", this.task);
    },
  },

  mounted() {
    // if updating task, set task to the task being updated
    if (this.updating_task) {
      this.task = this.task_to_update;
    }
  },
};
</script>
