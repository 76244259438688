module.exports = {
  required: (v) => !!v || "Required.",

  number: (v) =>
    v === "" || v === null || /^\d+$/.test(v) || "Input must be a number",

  contact_number: [
    (v) => !v || /\d/g.test(v) || "Phone must be numbers only (ex. 7803334444)",
    (v) =>
      !v ||
      v.length == 10 ||
      v.length == 9 ||
      "Phone number must be 9 - 10 digits no spaces or characters (format 7803334444)",
    (v) => (v && !!v) || "Number is required",
  ],

  email: [
    (v) => (v && !!v) || "E-mail is required",
    (v) => (v && /.+@.+/.test(v)) || "E-mail must be valid",
  ],
};
